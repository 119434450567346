:root {
   --main_color01: #023A73;
   --main_color02: #14299F;
   --main_color03: #0525c9;
   --main_color04: #1c61ff;
   --sub_color01: #202020;
   --sub_color02: #484848;
   --sub_color03: #707070;
   --sub_color04: #AEAEAE;
   --sub_color05: #E4E4E4;
   --sub_color06: #FCFCFC;
   --sub_color07: #b3b2b2;
   --sub_color08: #f5f5f5;
   --sub_color09: #ffe319;
   --sub_color10: #d8d8d8;
   --sub_color11: #f2f7ff;
}

* {
   padding: 0px;
   margin: 0px;
   word-break: keep-all;
   background-repeat: no-repeat;
}

img {
   vertical-align: top;
}

a {
   display: block;
   text-decoration: none;
}

label {
   display: block;
}

.hide {
   display: none;
}

.hide_txt {
   text-indent: -9999px;
}

.icon_loading_wrap {
   overflow: hidden;
}

.icon_loading_wrap .icon_loading {
   width: 100%;
   text-align: center;
   font-size: 26px;
   padding: 50px 0px;
   color: var(--subColor_04);
}

/* top Btn Start */
.Top_Scroll_btn {
   z-index: 999;
   position: fixed;
   bottom: 48px;
   right: 48px;
   width: 48px;
   height: 48px;
   border-radius: 100%;
   box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
   background-color: #fff;
   display: flex;
   align-items: center;
   justify-content: center;
   opacity: 0;
   transition: .3s;
}

.Top_Scroll_btn.active {
   opacity: 1;
}

.Top_Scroll_btn i {
   font-size: 24px;
   color: #464646;
   z-index: 2;
}

.Top_Scroll_btn .progress_bar {
   width: 100%;
   height: 100%;
   border-radius: 50%;
   position: absolute;
   left: 0;
   top: 0;
   z-index: 0;
}

.Top_Scroll_btn .progress_bar::before {
   content: '';
   position: absolute;
   left: 50%;
   top: 50%;
   transform: translate(-50%, -50%);
   width: 90%;
   height: 90%;
   background-color: #fff;
   border-radius: 100%;
}

/* top Btn End */

/* Common Start */
.wrap {
   max-width: 1440px;
   margin-left: auto;
   margin-right: auto;
}

.color_main3 {
   color: var(--main_color03);
}

.list.type_dot {
   position: relative;
   padding-left: 22px;
}

.list.type_dot::after {
   content: '';
   display: block;
   clear: both;
   position: absolute;
   top: 4px;
   left: 0px;
   width: 6px;
   height: 6px;
   border-radius: 50%;
   background-color: var(--main_color03);
}

.iframe_video_wrap {
   position: relative;
   padding-bottom: 56.25%;
   height: 0;
   overflow: hidden;
}

.iframe_video_wrap iframe {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
}

/* Common End */

/* Header Start */
#header {
   z-index: 999;
   position: fixed;
   top: 40px;
   left: 50%;
   transform: translateX(-50%);
   width: 100%;
}

#header #gnb {
   width: 100%;
   max-width: 1840px;
   border-radius: 9999px;
   padding-left: 32px;
   margin: 0px auto;
   box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.16);
   background-color: #fff;
}

#header .gnb_ul {
   display: flex;
   justify-content: space-between;
   align-items: center;
   width: 100%;
   margin-left: auto;
   margin-right: auto;
}

#header .gnb_ul .logo {
   width: auto;
   height: 48px;
}

#header .gnb_ul .nav_li {
   height: 80px;
}

#header .gnb_ul .gnb_dep2 {
   display: flex;
   align-items: center;
}

#header .gnb_ul .nav_dep2 {
   gap: 54px;
   /* height: 100%; */
}

#header .gnb_ul .gnb_dep2 .global_wrap {
   display: flex;
   align-items: flex-start;
   justify-content: center;
   width: 80px;
}

#header .gnb_ul .gnb_dep2 .global_wrap .icon_global {
   width: 32px;
   height: auto;
}

#header .gnb_ul .gnb_dep2 .open_gnb {
   width: 80px;
   font-size: 32px;
   color: var(--main_color03);
}

#header .gnb_ul .gnb_dep2 .gnb_dep2_item {
   position: relative;
   height: 80px;
}

#header .gnb_ul .gnb_dep2 .gnb_dep2_item>.btn_link,
#header .gnb_ul .gnb_dep2 .gnb_dep2_item>.btn_global {
   height: 80px;
   line-height: 80px;
   box-sizing: content-box;
   padding-bottom: 5px;
}

#header .gnb_ul .gnb_dep3 {
   display: none;
   position: absolute;
   top: 84px;
   left: 50%;
   transform: translateX(-50%);
   border-radius: 10px;
   padding: 26px 0px;
   box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.16);
   background-color: #fff;
}

#header .gnb_ul .gnb_dep2 .gnb_dep2_item:hover>.gnb_dep3 {
   display: block;
}

#header .gnb_ul .gnb_dep3 .btn_link {
   white-space: nowrap;
   display: block;
   width: 100%;
   text-align: center;
   font-weight: 600;
   padding: 12px 42px;
}

#header .gnb_ul .gnb_dep3 .btn_link:hover {
   text-decoration: underline;
   font-weight: 700;
   color: var(--main_color03);
}

.header_wrap .gnb_active_wrap {
   z-index: 9999;
   position: fixed;
   top: 0px;
   left: 0px;
   display: none;
   justify-content: center;
   align-items: center;
   width: 100vw;
   height: 100vh;
   background-color: rgba(34, 34, 34, 0.9)
}

.header_wrap .gnb_active_wrap * {
   color: #fff;
}

.header_wrap .gnb_active_wrap.on {
   display: flex;
}

.header_wrap .gnb_active_wrap .dep01_item {
   display: flex;
   gap: 120px;
}

.header_wrap .gnb_active_wrap .dep01_item:not(:last-of-type) {
   margin-bottom: 80px;
}

.header_wrap .gnb_active_wrap .dep01_item .tit {
   cursor: text;
   display: block;
   width: 270px;
   text-align: left;
}

.header_wrap .gnb_active_wrap .dep01_item .dep02_item_wrap {
   display: flex;
   gap: 80px;
}

.header_wrap .gnb_active_wrap .btn_close {
   position: absolute;
   top: 48px;
   right: 48px;
   width: 80px;
   height: 80px;
   border-radius: 10px;
   background-color: var(--main_color03);
}

/* Header End */

/* Main Page Start */
.main_page .mb_wrap {
   height: 100vh;
}

.main_page .mb_wrap .mb {
   position: relative;
   display: flex;
   align-items: center;
   justify-content: center;
   width: 100%;
   height: 100%;
}

.main_page .mb_wrap .mb * {
   color: #fff;
}

.main_page .mb_wrap .mb .mb_bg_img,
.main_page .mb_wrap .mb .mb_bg_video {
   z-index: -9;
   position: absolute;
   top: 0px;
   left: 0px;
   width: 100%;
   height: 100%;
   object-fit: cover;
}

.main_page .mb_wrap .mb.swiper-slide-active .banner_text_wrap {
   width: 100%;
}

.main_page .mb_wrap .mb.swiper-slide-active .banner_text_wrap .main_tit {
   width: 100%;
   text-align: center;
}

.main_page .mb_wrap .mb .banner_text_wrap .main_tit .text {
   color: transparent;
   -webkit-text-stroke: 2px #fff;
}

.main_page .mb_wrap .mb.swiper-slide-active .banner_text_wrap .main_tit .text {
   animation: MbText 0.3s 0.6s forwards;
   opacity: 0;
}

.main_page .mb_wrap .mb .banner_text_wrap .main_tit .point {
   opacity: 1;
   color: var(--main_color03);
   -webkit-text-stroke: 2px var(--main_color03);
}

.main_page .mb_wrap .mb.swiper-slide-active .banner_text_wrap .main_tit .point.text_G {
   animation: text_G 0.3s ease-in 0.3s forwards;
   margin-left: 135px;
}

.main_page .mb_wrap .mb.swiper-slide-active .banner_text_wrap .main_tit .point.text_O {
   animation: text_O 0.3s ease-in 0.3s forwards;
   margin-left: -330px;
}

.main_page .mb_wrap .mb.swiper-slide-active .banner_text_wrap .main_tit .point.text_S {
   animation: text_S 0.3s ease-in 0.3s forwards;
   margin-left: -385px;
}

@keyframes text_G {
   0% {
      margin-left: 135px;
   }

   100% {
      margin-left: 0px;
   }
}

@keyframes text_O {
   0% {
      margin-left: -330px;
   }

   100% {
      margin-left: 0px;
   }
}

@keyframes text_S {
   0% {
      margin-left: -385px;
   }

   100% {
      margin-left: 0px;
   }
}

@keyframes MbText {
   0% {
      opacity: 0;
   }

   100% {
      opacity: 1;
   }
}

.main_page .content.type01 {
   position: relative;
   height: 980px;
   background-size: auto 100%;
   background-position: center center;
   /* background-attachment: fixed; */
}

.main_page .section01 {
   background-image: url(../images/home/section01_bg.webp);
}

.main_page .content.type01 .txt_wrap {
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
}

.main_page .content.type01 .txt_wrap .txt {
   position: relative;
}

.main_page .content.type01 .txt_wrap .txt:not(:last-of-type) {
   margin-bottom: 40px;
}

.main_page .content.type01 .txt_wrap .txt.point {
   width: max-content;
   margin-left: auto;
   margin-right: auto;
}

.main_page .content.type01 .txt_wrap .txt.point::after {
   content: '';
   display: block;
   clear: both;
   z-index: -9;
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   width: 100%;
   height: 100%;
   box-sizing: content-box;
   padding: 24px 40px;
   background-color: rgba(255, 255, 255, 0.27);
}

.main_page .section02 {
   background-image: url(../images/home/section02_bg.webp);
}

.main_page .section03 {
   background-image: url(../images/home/section03_bg.webp);
}

.main_page .section04 {
   padding: 200px 0px 130px 0px;
   background-image: url(../images/home/section04_bg.webp);
}

.main_page .section04>.tit {
   -webkit-text-stroke: 1px var(--main_color03);
   color: transparent;
}

.main_page .section04 .item_wrap {
   display: flex;
   justify-content: space-between;
   padding: 0px 36px;
}

.main_page .section04 .item_wrap .item {
   width: 440px;
   padding: 40px 40px 64px;
   border-radius: 40px;
   transition: all 0.3s ease-out;
}

.main_page .section04 .item_wrap .item:hover,
.main_page .section04 .item_wrap .item:focus {
   background-color: var(--main_color03);
}

.main_page .section04 .item_wrap .item.type02:hover,
.main_page .section04 .item_wrap .item.type02:focus {
   background-color: #fff;
}

.main_page .section04 .item_wrap .item .img_wrap {
   display: flex;
   justify-content: center;
   align-items: flex-end;
   width: 100%;
   max-width: 350px;
   height: 230px;
   margin: 0px auto 33px auto;
}

.main_page .section04 .item_wrap .item .img.size01 {
   width: 250px;
}

.main_page .section04 .item_wrap .item .img.size02 {
   width: 350px;
}

.main_page .section04 .item_wrap .item .img.size02 {
   width: 205px;
}

.main_page .section04 .item_wrap .item .num {
   color: var(--main_color03);
}

.main_page .section04 .item_wrap .item.type01:hover .num {
   color: #fff;
}

.main_page .section04 .item_wrap .item:hover .txt,
.main_page .section04 .item_wrap .item:focus .txt {
   color: #fff;
}

.main_page .section04 .item_wrap .item .btn_link {
   display: flex;
   justify-content: center;
   align-items: center;
   width: 48px;
   height: 48px;
   border-radius: 50%;
   margin-left: auto;
   margin-right: auto;
   background-color: var(--main_color03);
}

.main_page .section04 .item_wrap .item.type01:hover .btn_link,
.main_page .section04 .item_wrap .item.type01:focus .btn_link {
   background-color: var(--sub_color09);
}

.main_page .section04 .item_wrap .item.type01:hover .btn_link .icon,
.main_page .section04 .item_wrap .item.type01:focus .btn_link .icon {
   color: var(--main_color03);
}

.main_page .section05 {
   height: 980px;
   background-image: url(../images/home/section05_bg.webp)
}

.main_page .section05 .swiper01 {
   height: 100%;
}

.main_page .section05 .swiper01 .swiper-slide {
   display: flex;
   align-items: center;
   justify-content: space-between;
}

.main_page .section05 .swiper01 .slide_wrap {
   width: 100%;
   height: 100%;
   display: flex;
   align-items: center;
}

.main_page .section05 .swiper01 .slide_wrap .txt_img_wrap {
   position: relative;
   width: fit-content;
}

.main_page .section05 .swiper01 .slide_wrap .txt_img_wrap .img {
   z-index: -9;
   position: absolute;
   animation: section05_rotate_img 10s linear 0s infinite;
   top: 50%;
   right: 0px;
   width: 142px;
}

@keyframes section05_rotate_img {
   0% {
      transform: translate(35%, -50%) rotate(0deg);
   }

   100% {
      transform: translate(35%, -50%) rotate(360deg);
   }
}

.main_page .section05 .swiper01 .mb_tit {
   display: none;
}

.main_page .section05 .swiper01 .slide_wrap .tit .point,
.main_page .section05 .swiper01 .mb_tit .point {
   color: #fff;
}

.main_page .section05 .swiper01 .slide_wrap .tit,
.main_page .section05 .swiper01 .mb_tit {
   -webkit-text-stroke: 1px #fff;
   color: transparent;
}

.main_page .section05 .swiper01 .slide_wrap .btn_link {
   display: flex;
   justify-content: space-between;
   align-items: center;
   gap: 24px;
   width: fit-content;
   border: 1px solid #fff;
   border-radius: 9999px;
   padding: 16px 40px;
}

.main_page .section05 .swiper01 .swiper-pagination {
   bottom: 160px;
   display: flex;
   gap: 8px;
}

.main_page .section05 .swiper01 .swiper-pagination-bullet {
   opacity: 1;
   display: block;
   width: 72px;
   height: 4px;
   border-radius: 0px;
   margin: 0px;
   background-color: rgba(255, 255, 255, 0.4);
}

.main_page .section05 .swiper01 .swiper-pagination-bullet-active {
   opacity: 1;
   background-color: #fff;
}

.main_page .section05 .swiper01 .mockup_img {
   max-width: 750px;
}

.main_page .section06 .tit {
   color: transparent;
   -webkit-text-stroke: 1px var(--main_color04);
}

.main_page .section06 .tit .point {
   color: var(--main_color04);
}

.main_page .link_wrap.type01 {
   display: flex;
   align-items: center;
   gap: 12px;
}

.main_page .link_wrap.type01 * {
   color: var(--sub_color07);
}

.main_page .link_wrap.type01 .btn_link {
   display: flex;
   align-items: center;
   gap: 10px;
   padding: 12px 24px;
   border: 1px solid var(--sub_color07);
   border-radius: 9999px;
}

.main_page .link_wrap.type01 .btn_link.on {
   border-color: var(--sub_color09);
   background-color: var(--sub_color09);
}

.main_page .link_wrap.type01 .btn_link.on * {
   color: var(--sub_color01);
}

.main_page .swiper.type02 {
   width: 1600px;
}

.main_page .swiper.type02 .slide {
   width: 300px;
}

.main_page .swiper.type02 .slide .des {
   text-overflow: ellipsis;
   overflow: hidden;
   display: -webkit-box;
   -webkit-box-orient: vertical;
   -webkit-line-clamp: 3;
}

.main_page .section07 {
   background-image: url(../images/home/section07_bg_pattern.webp);
   background-size: cover;
   background-color: var(--main_color03);
}

.main_page .section07 .tit {
   color: transparent;
   -webkit-text-stroke: 1px #fff;
}

.main_page .section07 .tit .point {
   color: var(--sub_color09);
}

.main_page .section07 .link_wrap .btn_link {
   border-color: var(--sub_color08);
   background-color: var(--sub_color08);
}

.main_page .section07 .swiper.type02 .slide .des {
   color: #fff;
}

.main_page .section08 .container {
   display: flex;
   justify-content: space-between;
   align-items: flex-start;
}

.main_page .section08 .container .text_wrap {
   position: sticky;
   top: 190px;
   width: fit-content;
   max-width: 650px;
   padding-right: 24px;
}

.main_page .section08 .container .text_wrap .tit {
   color: transparent;
   -webkit-text-stroke: 1px var(--main_color04);
}

.main_page .section08 .container .text_wrap .tit .point {
   color: var(--main_color04);
}

.main_page .section08 .container .text_wrap .btn_moreview {
   display: flex;
   gap: 16px;
   width: fit-content;
   border: 1px solid var(--sub_color04);
   border-radius: 9999px;
   padding: 12px 24px;
}

.main_page .section08 .container .box_wrap {
   width: 600px;
}

.main_page .section08 .container .content_wrap,
.main_page .section08 .container .content_wrap .scroll_content {
   width: 100%;
}

.main_page .section08 .container .scroll_content {
   display: flex;
   flex-direction: column;
}

.main_page .section08 .container .scroll_content .item {
   width: 100%;
   max-width: 570px;
}

.main_page .section08 .container .scroll_content .item.type01 {
   margin-left: auto;
}

.main_page .section08 .container .scroll_content .item.type02 {
   margin-left: 60px;
}

.main_page .section09 {
   background-color: var(--sub_color08);
   background-image: url(../images/home/section09_bg_pattern.webp);
   background-size: cover;
}

.main_page .section09 .tit_wrap .tit {
   color: transparent;
   -webkit-text-stroke: 1px var(--main_color03);
}

.main_page .section09 .tit_wrap .tit .point {
   color: var(--main_color03);
}

.main_page .tit_wrap.type01 {
   display: flex;
   align-items: center;
   justify-content: center;
   gap: 16px;
}

.main_page .tit_wrap.type01.type01-1 {
   justify-content: flex-start;
}

.main_page .section06 .tit_wrap .tit {
   color: transparent;
   -webkit-text-stroke: 1px var(--main_color04);
}

.main_page .section06 .tit_wrap .tit .point {
   color: var(--main_color04);
}

.main_page .tit_wrap.type01 .btn_link {
   display: flex;
   justify-content: center;
   align-items: center;
   width: 56px;
   height: 56px;
   border-radius: 50%;
   background-color: var(--sub_color09);
}

.main_page .section09 .swiper04 {
   padding-bottom: 50px;
}

.main_page .section09 .swiper04 .swiper-pagination-bullet {
   width: 72px;
   height: 4px;
   border-radius: 0px;
   background-color: #fff;
}

.main_page .section09 .swiper04 .swiper-pagination-bullet-active {
   background-color: var(--main_color03);
}

.main_page .section10.contact_us {
   display: flex;
   justify-content: center;
   align-items: center;
   height: 980px;
}

.main_page .section10.contact_us .item01 {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   width: 100%;
   max-width: 930px;
   height: 100%;
   background-image: url(../images/home/contactus_bg.webp);
   background-size: cover;
}

.main_page .section10.contact_us .item01 * {
   color: #fff;
}

.main_page .section10.contact_us .item01 .tit {
   color: transparent;
   -webkit-text-stroke: 1px #fff;
}

.main_page .section10.contact_us .item01 .tit .point {
   color: var(--sub_color09);
}

.main_page .section10.contact_us .item01 .icon_wrap {
   display: flex;
   align-items: center;
   gap: 18px;
}

.main_page .section10.contact_us .item01 .icon_wrap .icon {
   display: flex;
   justify-content: center;
   align-items: center;
   width: 48px;
   height: 48px;
   border-radius: 50%;
   color: var(--main_color03);
   background-color: #fff;
}

.main_page .section10.contact_us .item02 {
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: column;
   width: 100%;
   max-width: 990px;
   height: 100%;
   background-color: var(--sub_color11);
}

.main_page .section10.contact_us .item02 .inquiry_from {
   width: 100%;
   max-width: 664px;
}

.main_page .section10.contact_us .item02 .input_box_wrap {
   display: flex;
   justify-content: space-between;
   gap: 24px;
}

.main_page .section10.contact_us .item02 .input_box_wrap .dep {
   width: 100%;
}

.main_page .section10.contact_us .item02 .input_box_wrap .dep label .point {
   color: #a62624;
}

.main_page .section10.contact_us .item02 .input_box_wrap .input_box {
   width: 100%;
   height: 50px;
   line-height: 50px;
   border: 1px solid #d8d8d8;
   padding: 0px 16px;
   background-color: #fff;
}

.main_page .section10.contact_us .item02 .input_box_wrap .input_box#inquiry {
   height: 240px;
}

.main_page .section10.contact_us .item02 .input_box_wrap .privacy_agree_wrap {
   display: flex;
   align-items: center;
   gap: 8px;
}

.main_page .section10.contact_us .item02 .input_box_wrap .privacy_agree_wrap input[type="checkbox"] {
   -webkit-appearance: none;
   -moz-appearance: none;
   appearance: none;
   width: 20px;
   height: 20px;
   border: 1px solid #bfbfbf;
   border-radius: 0px;
   position: relative;
}

.main_page .section10.contact_us .item02 .input_box_wrap .privacy_agree_wrap input[type="checkbox"]:checked {
   -webkit-appearance: checkbox;
   -moz-appearance: checkbox;
   appearance: checkbox;
}

.main_page .section10.contact_us .item02 .input_box_wrap .privacy_agree_wrap .view_all {
   text-decoration: underline;
   color: var(--main_color03);
}

.main_page .section10.contact_us .item02 .btn.type01 {
   display: flex;
   justify-content: center;
   align-items: center;
   width: 200px;
   height: 53px;
   border-radius: 9999px;
   margin-left: auto;
   margin-right: auto;
   background-color: var(--sub_color09);
}

/* Main Page End */

/* Sub Page Start */
.sub_page .mb_wrap {
   position: relative;
   height: 550px;
   padding-top: 245px;
   background-size: cover;
}

.sub_page .top_item {
   display: flex;
   justify-content: space-between;
   align-items: center;
}

.sub_page .top_item * {
   color: #fff;
}

.sub_page .top_item .nav {
   display: flex;
   align-items: center;
   gap: 8px;
}

.sub_page .mb_wrap>.wrap>.gnb {
   position: absolute;
   bottom: -1px;
   left: 50%;
   transform: translateX(-50%);
   width: 100%;
   border-radius: 30px 30px 0px 0px;
   background-color: #fff;
}

.sub_page .mb_wrap>.wrap>.gnb .gnb_item_wrap {
   display: flex;
   justify-content: center;
   align-items: center;
   gap: 40px;
}

.sub_page .mb_wrap>.wrap>.gnb .gnb_item_wrap.mo_only {
   display: none;
}

.sub_page .mb_wrap>.wrap>.gnb .gnb_item_wrap .gnb_item {
   height: 70px;
   line-height: 70px;
}

.sub_page .mb_wrap>.wrap>.gnb .gnb_item_wrap .gnb_item.on {
   position: relative;
   font-weight: 600;
   color: var(--main_color03);
}

.sub_page .mb_wrap>.wrap>.gnb .gnb_item_wrap .gnb_item.on::after {
   content: '';
   display: block;
   clear: both;
   position: absolute;
   bottom: 0px;
   left: 0px;
   width: 100%;
   height: 2px;
   background-color: var(--main_color03);
}

.top_nav.type_01 {
   width: 100%;
   border-bottom: 2px solid var(--sub_color03);
   padding-bottom: 32px;
   margin-bottom: 24px;
}

.top_nav.type_01 .search_box {
   display: flex;
   justify-content: space-between;
   align-items: center;
   width: 400px;
   height: 50px;
   border: 2px solid var(--sub_color05);
   padding: 0px 8px 0px 24px;
   margin-left: auto;
}

.top_nav.type_01 .search_box #search {
   width: 80%;
}

.top_nav.type_01 .search_box #product_search {
   width: 85%;
   height: 100%;
}

.top_nav.type_01 .search_box .icon_search {
   display: flex;
   justify-content: center;
   align-items: center;
   width: 35px;
   height: 35px;
   font-size: 19px;
   border-radius: 50%;
   color: #fff;
   background-color: var(--main_color03);
}

/* Sub Page End */

/* Company Start */
.sub_page.company .mb_wrap {
   background-image: url(../images/company/main_banner.webp);
}

.sub_page.core_values .section01 .tit .point {
   font-weight: bold;
   color: var(--main_color03);
}

.sub_page.core_values .section01 .item01 {
   display: flex;
   align-items: flex-end;
}

.sub_page.core_values .section01 .item01 .mv {
   max-width: 740px;
   height: auto;
}

.sub_page.core_values .section01 .item01 .icon {
   width: 28px;
   height: auto;
}

.sub_page.core_values .section01 .item01 .text_wrap {
   padding: 40px 48px;
   border-radius: 0px 0px 24px 0px;
   background-color: var(--sub_color11);
}

.sub_page.core_values .section02 .item.type01 {
   display: flex;
   justify-content: space-between;
   border-top: 1px solid var(--sub_color05);
   padding-top: 48px;
   margin-top: 48px;
}

.sub_page.core_values .section02 .left_content {
   display: flex;
   align-items: flex-start;
}

.sub_page.core_values .section02 .left_content .num,
.sub_page.core_values .section02 .left_content .point {
   font-weight: bold;
   color: var(--main_color03);
}

.sub_page.core_values .section02 .right_content {
   max-width: 970px;
}

.sub_page.core_values .section02 .right_content .banner_item {
   display: flex;
   justify-content: center;
   align-items: flex-start;
   flex-direction: column;
   height: 265px;
   background-size: cover;
}

.sub_page.core_values .section02 .item01 .right_content .banner_item {
   background-image: url(../images/company/core_values_item01_banner.webp);
}

.sub_page.core_values .section02 .item01 .right_content .banner_item .check_list_wrap .icon {
   color: var(--main_color03);
}

.sub_page.core_values .section02 .item02 .right_content .banner_item {
   background-image: url(../images/company/core_values_item02_banner.webp);
}

.sub_page.core_values .section02 .item02 .right_content .banner_item * {
   color: #fff;
}

.sub_page.core_values .section02 .right_content .check_list_wrap {
   display: flex;
   gap: 8px;
}

.sub_page.core_values .section02 .item03 .right_content .banner_item {
   background-image: url(../images/company/core_values_item03_banner.webp);
}

.vision_mission .section02 {
   padding: 80px 0px 72px 0px;
   background-image: url(../images/company/vision_mission_bg01.webp);
   background-size: cover;
}

.vision_mission .section02 .txt {
   max-width: 1385px;
   margin-left: auto;
   margin-right: auto;
}

.vision_mission .section02 .list_wrap {
   display: flex;
   justify-content: center;
   gap: 80px;
}

.vision_mission .section02 .list_wrap .img {
   width: 295px;
   height: auto;
}

.vision_mission .section03 .category {
   color: var(--main_color03);
}

.vision_mission .section03 .tab_control_wrap {
   display: flex;
   width: 100%;
   border-radius: 10px 10px 0px 0px;
   background-color: #e5effe;
}

.vision_mission .section03 .tab_control_wrap .control_item {
   width: 33.3%;
}

.vision_mission .section03 .tab_control_wrap .control_item .btn_control {
   width: 100%;
   height: 64px;
   border-radius: 10px 10px 0px 0px;
   background-color: #e5effe;
}

.vision_mission .section03 .tab_control_wrap .control_item .btn_control.on {
   color: #fff;
   background-color: var(--main_color03);
}

.vision_mission .section03 .tab_item_wrap {
   border: 1px solid var(--sub_color05);
   border-top: 0px none;
   padding: 48px;
}

.vision_mission .section03 .tab_item_wrap .tab_item {
   display: none;
   justify-content: space-between;
   align-items: center;
   gap: 218px;
}

.vision_mission .section03 .tab_item_wrap .tab_item.on {
   display: flex;
}

.vision_mission .section03 .tab_item_wrap .tab_item .img {
   max-width: 312px;
   height: auto;
}

.contact_inquire .top_head_wrap {
   display: flex;
   justify-content: space-between;
   align-items: flex-end;
   border-bottom: 2px solid var(--sub_color01);
   padding-bottom: 32px;
   margin-bottom: 40px;
}

.contact_inquire .top_head_wrap .txt .point {
   color: #ff0400;
}

.contact_inquire .inquire_from .item_01 {
   display: flex;
   gap: 40px;
}

.contact_inquire .inquire_from .input_wrap {
   margin-bottom: 32px;
}

.contact_inquire .inquire_from .input_wrap.message_wrap {
   border-bottom: 1px solid #c5c5c5;
   padding-bottom: 40px;
   margin-bottom: 40px;
}

.contact_inquire .inquire_from .input_wrap input,
.contact_inquire .inquire_from .input_wrap textarea {
   display: block;
   width: 700px;
   height: 52px;
   border: 1px solid var(--sub_color10);
   border-radius: 4px;
   padding: 16px;
   margin-top: 16px;
}

.contact_inquire .inquire_from .input_wrap.message_wrap textarea {
   width: 100%;
   height: 250px;
}

.contact_inquire .inquire_from .input_wrap .point {
   color: #a62624;
}

.contact_inquire .inquire_from .input_wrap.upload_wrap {
   display: flex;
   align-items: center;
   gap: 16px;
   padding-bottom: 32px;
   border-bottom: 1px solid #c5c5c5;
}

.contact_inquire .inquire_from .input_wrap .label_upload {
   display: flex;
   justify-content: center;
   align-items: center;
   width: 148px;
   height: 52px;
   color: #fff;
   background-color: #7d7d7d;
}

.contact_inquire .inquire_from .input_wrap .input_upload {
   display: none;
}

.contact_inquire .privacy_wrap .info_wrap {
   padding: 32px;
   background-color: #f9f9f9;
}

.contact_inquire .privacy_wrap .info_wrap .list_wrap .item {
   display: flex;
   gap: 45px;
}

.contact_inquire .privacy_wrap .info_wrap .list_wrap .item .tit,
.contact_inquire .privacy_wrap .info_wrap .list_wrap .item .txt {
   color: #999;
}

.contact_inquire .privacy_wrap .info_wrap .list_wrap .item .tit {
   width: 105px;
}

.contact_inquire .btn_submit {
   display: block;
   width: 870px;
   height: 70px;
   margin-left: auto;
   margin-right: auto;
   color: #fff;
   background-color: var(--main_color03);
}

.contact_inquire .privacy_agree_wrap {
   display: flex;
   align-items: center;
   gap: 8px;
}

.contact_inquire input[type="checkbox"] {
   -webkit-appearance: none;
   -moz-appearance: none;
   appearance: none;
   width: 20px;
   height: 20px;
   border: 1px solid #bfbfbf;
   border-radius: 0px;
   position: relative;
}

.contact_inquire input[type="checkbox"]:checked {
   -webkit-appearance: checkbox;
   -moz-appearance: checkbox;
   appearance: checkbox;
}

/* Company End */

/* Math Education Standards Start */
.math_education_standards .mb_wrap {
   background-image: url(../images/math-education-standards/main_banner.webp);
}

.math_education_standards .section01 {
   display: flex;
   justify-content: space-between;
}

.math_education_standards .section01 .txt {
   width: 100%;
   max-width: 890px;
}

.math_education_standards .section02 {
   display: flex;
   justify-content: center;
   align-items: center;
   height: 325px;
   padding: 0px 40px;
   background-size: cover;
}

.math_education_standards.math_essence_intro .section02 {
   background-image: url(../images/math-education-standards/math-essence-intro_banner.jpg);
}

.math_education_standards.ideal_math_education .section02 {
   background-image: url(../images/math-education-standards/ideal_math_education_banner.webp);
}

.math_education_standards.general_math .section02 {
   background-image: url(../images/math-education-standards/general_math_banner.webp);
}

.math_education_standards .section03 .item {
   display: flex;
   align-items: center;
   gap: 32px;
   border-bottom: 1px solid var(--sub_color05);
}

.math_education_standards .section03 .item .num_img {
   width: 73px;
   height: auto;
}

/* Math Education Standards End */

/* Consumer Centric Education Start */
.consumer_centric_educatio .mb_wrap {
   background-image: url(../images/consumer-centric-education/main_banner.webp);
}

.consumer_centric_educatio .section01 .tit .point {
   color: var(--main_color03);
}

.consumer_centric_educatio .section02 .item_warp {
   display: flex;
   gap: 24px;
}

.consumer_centric_educatio .section02 .item_warp .item {
   position: relative;
   width: 464px;
   height: 214px;
   border: 1px solid var(--sub_color05);
   border-radius: 24px;
   padding: 40px 32px;
   background-size: 80px auto;
   background-position: bottom 32px right 32px;
}

.it_based_math_education_service .section02 .item_warp .item.item01 {
   background-image: url(../images/consumer-centric-education/it_based_math_education_service_icon01.webp);
}

.it_based_math_education_service .section02 .item_warp .item.item02 {
   background-image: url(../images/consumer-centric-education/it_based_math_education_service_icon02.webp);
}

.it_based_math_education_service .section02 .item_warp .item.item03 {
   background-image: url(../images/consumer-centric-education/it_based_math_education_service_icon03.webp);
}

.consumer_centric_educatio .section02 .item_warp .item .tit .point {
   position: relative;
}

.consumer_centric_educatio .section02 .item_warp .item .tit .point::after {
   content: '';
   display: block;
   clear: both;
   z-index: -9;
   position: absolute;
   bottom: 0px;
   left: 0px;
   width: 100%;
   height: 8px;
   background-color: #fcd113;
}

.consumer_centric_educatio .section02 .item_warp .bg_num {
   opacity: 0.64;
   position: absolute;
   bottom: 28px;
   left: 32px;
   color: var(--sub_color05);
}

.consumer_centric_educatio .section03 {
   display: flex;
   justify-content: space-between;
   align-items: center;
}

.consumer_centric_educatio .section03 .text_wrap {
   max-width: 710px;
}

.consumer_centric_educatio .section03 .text_wrap .tit {
   width: fit-content;
}

.consumer_centric_educatio .section03 .mockup_img {
   max-width: 500px;
}

.consumer_centric_educatio .contact_banner {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   height: 350px;
   background-image: url(../images/common/contact_banner.webp);
   background-size: cover;
}

.consumer_centric_educatio .contact_banner .btn_link {
   display: flex;
   justify-content: center;
   align-items: center;
   width: 200px;
   height: 53px;
   border-radius: 9999px;
   background-color: var(--sub_color09);
}

.ar_based_math_education_service .section02 .item_warp .item.item01 {
   background-image: url(../images/consumer-centric-education/ar_based_math_education_service_icon01.webp);
}

.ar_based_math_education_service .section02 .item_warp .item.item02 {
   background-image: url(../images/consumer-centric-education/ar_based_math_education_service_icon02.webp);
}

.ar_based_math_education_service .section02 .item_warp .item.item03 {
   background-image: url(../images/consumer-centric-education/ar_based_math_education_service_icon03.webp);
}

.dt_based_math_education_service .section02 .item_warp .item.item01 {
   background-image: url(../images/consumer-centric-education/dt_based_math_education_service_icon01.webp);
}

.dt_based_math_education_service .section02 .item_warp .item.item02 {
   background-image: url(../images/consumer-centric-education/dt_based_math_education_service_icon02.webp);
}

.dt_based_math_education_service .section02 .item_warp .item.item03 {
   background-image: url(../images/consumer-centric-education/dt_based_math_education_service_icon3.webp);
}

/* Consumer Centric Education End */

/* General Math 144 Start */
.general_math_144 .mb_wrap {
   background-image: url(../images/general-math-144/main_banner.webp);
}

.elementary_education_curriculum .tab_wrap {
   display: flex;
   width: fit-content;
   border: 1px solid var(--sub_color05);
   margin-left: auto;
   margin-right: auto;
}

.elementary_education_curriculum .tab_wrap .tab_item .btn_tab {
   width: 207px;
   height: 70px;
}

.elementary_education_curriculum .tab_wrap .tab_item:not(:last-of-type) .btn_tab {
   border-right: 1px solid var(--sub_color05);
}

.elementary_education_curriculum .tab_wrap .tab_item .btn_tab.on {
   color: #fff;
   background-color: var(--main_color03);
}

.elementary_education_curriculum .product_list_wrap .product_count .num {
   color: var(--main_color03);
}

.elementary_education_curriculum .product_list_wrap .product_wrap {
   display: flex;
   flex-wrap: wrap;
   gap: 40px 32px;
}

.elementary_education_curriculum .product_list_wrap .product_wrap .product_item {
   width: 336px;
   width: calc((100% - 32px * 3) / 4);
}

.elementary_education_curriculum .product_list_wrap .product_wrap .product_item .product_tit {
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
   width: 100%;
}

.btn_link:hover>.icon_search_wrap.type01,
.btn_link:focus>.icon_search_wrap.type01 {
   display: flex;
}

.btn_link.type-search-wrap {
   position: relative;
}

.icon_search_wrap.type01 {
   display: none;
   position: absolute;
   top: 0px;
   left: 0px;
   width: 100%;
   height: 100%;
   justify-content: center;
   align-items: center;
   background-color: rgba(0, 0, 0, 0.5);
}

.elementary_education_curriculum .product_list_wrap .product_wrap .category_wrap {
   display: flex;
   gap: 8px;
}

.elementary_education_curriculum .product_list_wrap .product_wrap .category_wrap .category {
   border: 1px solid var(--main_color03);
   border-radius: 9999px;
   padding: 8px 12px;
   color: var(--main_color03);
}

.elementary_education_curriculum .product_list_wrap .product_wrap .price_wrap {
   display: flex;
}

.elementary_education_curriculum .product_list_wrap .product_wrap .price_wrap * {
   color: var(--sub_color04);
}

.elementary_education_curriculum .product_list_wrap .product_wrap .price_wrap .price_tit {
   border-right: 1px solid var(--sub_color04);
}

.general_math_detailpage {
   display: flex;
   align-items: center;
   gap: 90px;
}

.general_math_detailpage .product_img_wrap {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: center;
   gap: 24px;
   width: 610px;
   border-radius: 24px;
   padding: 48px 24px 24px;
   background-color: var(--sub_color11);
}

.general_math_detailpage .product_img_wrap .thumbnail {
   width: auto;
   height: 470px;
}

.general_math_detailpage .product_img_wrap .btn_preview {
   display: flex;
   align-items: center;
   gap: 8px;
   width: fit-content;
   border-radius: 9999px;
   padding: 12px 24px;
   background-color: #222;
}

.general_math_detailpage .product_img_wrap .btn_preview * {
   color: #fff;
}

.general_math_detailpage .info_wrap {
   width: 735px;
   max-width: 100%;
}

.general_math_detailpage .category_wrap {
   display: flex;
   gap: 8px;
}

.general_math_detailpage .category_wrap .category {
   border-radius: 9999px;
   padding: 12px 24px;
   color: #fff;
   background-color: var(--main_color03);
}

.general_math_detailpage .info_wrap .info_item {
   display: flex;
}

.general_math_detailpage .info_wrap .info_item .tit {
   width: 100px;
}

.general_math_detailpage .store_wrap {
   border-top: 1px solid var(--sub_color05);
}

.general_math_detailpage .store_wrap .tit_wrap {
   display: flex;
   align-items: center;
   gap: 8px;
}

.general_math_detailpage .store_wrap .tit_wrap .icon {
   color: var(--main_color03);
}

.general_math_detailpage .store_wrap .store_item_wrap {
   display: flex;
   flex-wrap: wrap;
   gap: 8px;
}

.general_math_detailpage .store_wrap .store_item_wrap .store_item .btn_link {
   border: 1px solid var(--sub_color04);
   border-radius: 8px;
   padding: 12px 24px;
}

.product_preview_wrap {
   z-index: 9999;
   position: fixed;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   width: 100%;
   height: 100%;
   background-color: rgba(0, 0, 0, 0.6);
}

.product_preview_wrap .product_preview_swiper {
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   width: 850px;
   height: 495px;
   border: 2px solid #d8d8d8;
   background-color: #fff;
}

.product_preview_wrap .product_preview_swiper .swiper-slide {
   display: flex;
   justify-content: center;
   align-items: center;
}

.product_preview_wrap .product_preview_swiper .swiper-slide .slide_img {
   width: auto;
   height: 100%;
}

.product_preview_wrap .product_preview_swiper .navigation_wrap {
   z-index: 9999;
   position: absolute;
   top: 50%;
   left: 0px;
   transform: translateY(-50%);
   display: flex;
   justify-content: space-between;
   width: 100%;
   padding: 0px 16px;
}

#root .product_preview_wrap .product_preview_swiper .navigation_wrap .swiper-button-disabled {
   opacity: 0.3;
}

.product_preview_wrap .product_preview_swiper .navigation_wrap .btn_prev,
.product_preview_wrap .product_preview_swiper .navigation_wrap .btn_next {
   display: flex;
   justify-content: center;
   align-items: center;
   width: 33px;
   height: 33px;
   box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
   border-radius: 50%;
   background-color: #fff;
}

.product_preview_wrap .btn_close {
   z-index: 9999;
   position: absolute;
   top: 16px;
   right: 16px;
   width: 33px;
   height: 33px;
   border-radius: 50%;
   background-color: #999;
}
.kids_froggy_144 .mb_wrap{
   background-image: url(../images/kids-froggy-144/main_banner.webp);
}
/* General Math 144 End */

/* Liberal Arts Program Start */
.liberal_arts_program .mb_wrap {
   background-image: url(../images/liberal-arts-program/main_banner.webp);
}

.tit.type_bb {
   border-bottom: 1px solid var(--sub_color05);
}

.program_overview .section02 .content {
   display: flex;
   gap: 56px;
   border: 1px solid var(--sub_color05);
}

.program_overview .section02 .content .main_img {
   width: 50%;
}

.program_overview .section02 .content.type02 {
   gap: 40px;
}

.program_overview .section02 .content.type02 .main_img {
   order: 1;
}

.program_overview .section02 .content.type02 .text_wrap {
   order: 0;
   padding-left: 56px;
}

.program_overview .section02 .content.type02 .text_wrap .num {
   padding-right: 0px;
}

.program_overview .section02 .content .text_wrap {
   width: 50%;
   padding: 24px 0px;
}

.program_overview .section02 .content .text_wrap .num {
   opacity: 0.4;
   width: 100%;
   text-align: right;
   font-size: 100px;
   padding-right: 40px;
   -webkit-text-stroke: 1px #007443;
   color: transparent;
}

.program_overview .section02 .content .text_wrap .tit {
   position: relative;
}

.program_overview .section02 .content .text_wrap .tit::before {
   content: '';
   display: block;
   clear: both;
   width: 48px;
   height: 4px;
   margin-bottom: 16px;
   background-color: var(--main_color03);
}

.program_overview .section02 .content .text_wrap .item_wrap .item {
   display: flex;
   align-items: center;
   gap: 8px;
}

.program_overview .section02 .content .text_wrap .item_wrap .item .icon {
   color: var(--main_color03);
}

.education_curriculum .section01 .main_img {
   width: 100%;
   max-width: 1020px;
   margin-left: auto;
   margin-right: auto;
}

.education_curriculum .section02 .tit .point {
   color: var(--main_color03);
}

.education_curriculum .section02 .item_wrap .item {
   display: flex;
   align-items: center;
   gap: 56px;
}

.education_curriculum .section02 .item_wrap .item.type_arrow {
   display: flex;
   justify-content: center;
   align-items: center;
   width: 50%;
   height: 130px;
}

.education_curriculum .section02 .item_wrap .item.type_arrow .icon {
   color: var(--main_color03);
}

.education_curriculum .section02 .item_wrap .item .main_img {
   width: 50%;
}

.education_curriculum .section02 .item_wrap .item .num {
   padding: 16px 32px;
   border-radius: 9999px;
   color: #fff;
   background-color: var(--main_color03);
}

.preliminary_diagnostic_program .section01 .list_wrap {
   padding: 40px 0px;
   background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='24' ry='24' stroke='%230525C9FF' stroke-width='2' stroke-dasharray='5%2c 7' stroke-dashoffset='18' stroke-linecap='square'/%3e%3c/svg%3e");
   border-radius: 24px;
}

.preliminary_diagnostic_program .section01 .list_wrap .list {
   display: flex;
   justify-content: center;
   gap: 8px;
}

.preliminary_diagnostic_program .section01 .list_wrap .list .icon {
   color: var(--main_color03);
}

.preliminary_diagnostic_program .section01 .item_wrap {
   display: flex;
}

.preliminary_diagnostic_program .section01 .item_wrap .item.type_arrow {
   display: flex;
   justify-content: center;
   align-items: center;
   width: 145px;
   height: 250px;
}

.preliminary_diagnostic_program .section01 .item_wrap .item.type_arrow .icon {
   color: var(--main_color03);
}

.preliminary_diagnostic_program .section01 .item_wrap .item .cir01,
.preliminary_diagnostic_program .section01 .item_wrap .item .cir02 {
   display: flex;
   justify-content: center;
   align-items: center;
   border-radius: 50%;
}

.preliminary_diagnostic_program .section01 .item_wrap .item .cir01 {
   width: 250px;
   height: 250px;
   padding: 20px;
   background-color: var(--sub_color11);
}

.preliminary_diagnostic_program .section01 .item_wrap .item .cir02 {
   width: 100%;
   height: 100%;
   background-color: #fff;
}

.preliminary_diagnostic_program .section01 .item_wrap .item .icon {
   width: auto;
   height: auto;
   max-width: 80px;
   max-height: 80px;
}

.preliminary_diagnostic_program .section01 .item_wrap .item .list_wrap02 .list:not(:last-of-type) {
   margin-bottom: 20px;
}
.preliminary_diagnostic_program .section01 .item_wrap .item .list_wrap02 .list.type_dot::after{
   top: 14px;
}

.membership .section01 .list_wrap01 {
   display: flex;
   gap: 24px;
}

.membership .section01 .list_wrap01>.list {
   max-width: 464px;
   width: 33.3%;
   border: 1px solid var(--sub_color04);
   border-radius: 24px;
}

.membership .section01 .list_wrap01>.list .tit {
   position: relative;
   width: fit-content;
}

.membership .section01 .list_wrap01>.list .tit::after {
   content: '';
   display: block;
   clear: both;
   z-index: -9;
   position: absolute;
   bottom: 7px;
   left: 0px;
   width: 100%;
   height: 8px;
   background-color: #fcd113;
}

.membership .section01 .list_wrap01>.list .price {
   border-radius: 9999px;
   padding: 12px 24px;
   color: #fff;
   background-color: var(--main_color03);
}

.membership .section01 .list_wrap02 {
   border-top: 1px solid var(--sub_color05);
   padding-top: 24px;
   margin-top: 24px;
}

.membership .section01 .list_wrap02 .list:not(:last-of-type) {
   margin-bottom: 16px;
}

.membership .section01 .list_wrap02 .list.type_dot {
   padding-left: 12px;
}

.membership .section01 .list_wrap02 .list.type_dot::after {
   top: 6px;
   width: 4px;
   height: 4px;
   background-color: #000;
}

/* Liberal Arts Program End */

/* Blueberry Espresso Start */
.blueberry_espresso .mb_wrap {
   background-image: url(../images/blueberry-espresso/main_banner.webp);
}

.math_letters .section02 .list_wrap {
   display: flex;
   flex-wrap: wrap;
   gap: 40px 40px;
}

.math_letters .section02 .list_wrap .list {
   width: calc((100% - 40px * 2) / 3);
   max-width: 453px;
}

.math_letters .section02 .list_wrap .list .thumbnail_wrap {
   overflow: hidden;
   position: relative;
   border-radius: 8px;
}

.math_letters .section02 .list_wrap .list .thumbnail_wrap .thumbnail {
   width: 100%;
}

.math_letters .section02 .list_wrap .list:hover .thumbnail_wrap .icon_bg {
   display: block;
}

.math_letters .section02 .list_wrap .list .thumbnail_wrap .icon_bg {
   z-index: 90;
   position: absolute;
   top: 0px;
   left: 0px;
   display: none;
   width: 100%;
   height: 100%;
   background-color: rgba(0, 0, 0, 0.5);
}

.math_letters .section02 .list_wrap .list .thumbnail_wrap .icon {
   z-index: 99;
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   color: #fff;
}

.blueberry_espresso.type_notice .top_nav.type_01 {
   margin-bottom: 0px;
}

.blueberry_espresso.type_notice .top_nav .top_item_wrap {
   display: flex;
   justify-content: space-between;
   align-items: flex-end;
}

.blueberry_espresso.type_notice .top_nav .top_item_wrap .point {
   color: var(--main_color03);
}

.blueberry_espresso.type_notice .list_wrap {
   display: flex;
   flex-direction: column;
   width: 100%;
}

.blueberry_espresso.type_notice .page_control_wrap.type_01 {
   margin-top: 0px;
}

.item.noitem {
   padding: 40px 0px;
}

.blueberry_espresso.type_notice .list_wrap .a_btn {
   align-items: center;
   border-bottom: 1px solid #e4e4e4;
   border-bottom: 1px solid var(--sub_color05);
   display: flex;
   gap: 34px;
   padding: 24px 20px;
}

.blueberry_espresso.type_notice .list_wrap .a_btn:hover,
.blueberry_espresso.type_notice .list_wrap .a_btn:focus {
   background-color: #f5faff;
}

.blueberry_espresso.type_notice .list_wrap .a_btn .notice {
   width: fit-content;
   border: 1px solid var(--main_color03);
   border-radius: 9999px;
   padding: 8px 16px;
   color: var(--main_color03);
}

.blueberry_espresso.type_notice .list_wrap .a_btn .date {
   color: var(--sub_color04);
}

.blueberry_espresso.news.blueberry_espresso.news_main img,
.blueberry_espresso.news.blueberry_espresso.news_main iframe,
.blueberry_espresso.news.blueberry_espresso.news_main video {
   display: none;
}

.blueberry_espresso.news .top_nav.type_01 {
   margin-bottom: 0px;
}

.blueberry_espresso.news .page_control_wrap {
   margin-top: 0px;
}

.control_wrap.type_01 .btn.noitem {
   opacity: 0.3;
}

/* .blueberry_espresso.news .list_wrap .item {
   padding-right: 40px;
} */

.blueberry_espresso.news .list_wrap .item:not(:last-of-type) {
   border-bottom: 1px solid var(--subColor_09);
}

.blueberry_espresso.news .list_wrap .item:hover,
.blueberry_espresso.news .list_wrap .item:focus {
   background-color: var(--sub_color08);
}

.blueberry_espresso.news .list_wrap .item .a_btn {
   overflow: hidden;
   display: flex;
   align-items: center;
   gap: 52px;
   padding: 32px 0px;
   padding: 32px 40px 32px 0px;
   border-bottom: 1px solid var(--sub_color05);
}

.blueberry_espresso.news .list_wrap .item .a_btn:hover,
.blueberry_espresso.news .list_wrap .item .a_btn:focus {
   background-color: transparent;
}

.blueberry_espresso.news .list_wrap .item .a_btn .text_wrap {
   max-width: 55%;
}

.blueberry_espresso.news .list_wrap .item .a_btn .text_wrap .tit {
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
   width: 100%;
}

.blueberry_espresso.news .list_wrap .item .thumbnail {
   object-fit: cover;
   width: 325px;
   height: 100%;
   margin-bottom: 0px;
}

.blueberry_espresso.news .list_wrap .item .txt {
   overflow: hidden;
   display: -webkit-box;
   -webkit-box-orient: vertical;
   -webkit-line-clamp: 3;
   width: 100%;
}

.blueberry_espresso.news .list_wrap .item .txt * {
   overflow: hidden;
   text-overflow: ellipsis;
   line-height: 28px;
   display: -webkit-box;
   max-height: calc(28px * 3);
   white-space: normal;
   word-wrap: break-word;
   word-break: keep-all;
   -webkit-line-clamp: 3;
   -webkit-box-orient: vertical;
   word-break: break-all;
   color: var(--sub_color04);
}

/* Blueberry Espresso End */

/* Page Control Start */
.page_control_wrap.type_01 {
   display: flex;
   justify-content: center;
   align-items: center;
   border-top: 2px solid var(--sub_color01);
   padding-top: 40px;
   margin-top: 40px;
}

.page_control_wrap.type_01 .control_btn {
   width: 40px;
   height: 40px;
   border-radius: 50%;
}

.page_control_wrap.type_01 .control_btn.on {
   color: #fff;
   background-color: #333;
}

/* Page Control End */

/* Not Found Start */
.notfound {
   display: flex;
   justify-content: center;
   align-items: center;
   height: 90vh;
}

.notfound .txt_wrap {
   text-align: center;
}

.notfound .txt_wrap .xi-error {
   font-size: 60px;
   color: var(--sub_color04);
}

.notfound .txt_wrap .tit {
   line-height: 60px;
}

.notfound .txt_wrap .btn_a {
   width: fit-content;
   border-radius: 9999px;
   padding: 12px 36px;
   margin-left: auto;
   margin-right: auto;
   background-color: var(--sub_color09);
}

/* Not Found End */

/* PrivacyAgreement All Start */
.privacy_agreement_all_wrap {
   z-index: 9999;
   position: fixed;
   top: 0px;
   left: 0px;
   width: 100%;
   height: 100%;
   background-color: rgba(0, 0, 0, 0.4)
}

.privacy_agreement_all_wrap .privacy_agreement_all {
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   width: 460px;
   background-color: #fff;
}

.privacy_agreement_all_wrap .privacy_agreement_all .title_wrap {
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: 16px 24px;
   background-color: var(--sub_color01);
}

.privacy_agreement_all_wrap .privacy_agreement_all .title_wrap * {
   color: #fff;
}

.privacy_agreement_all_wrap .text_wrap {
   padding: 24px;
}

.privacy_agreement_all_wrap .text_wrap .item {
   display: flex;
   gap: 45px;
}

.privacy_agreement_all_wrap .text_wrap .item * {
   color: #999;
}

.privacy_agreement_all_wrap .text_wrap .item .tit {
   width: 100px;
   min-width: 100px;
}

/* PrivacyAgreement All End */

/* Detail Page Start */
.detail_common {
   margin-top: 170px;
   border-top: 2px solid var(--sub_color03);
}

.detail_common .detail_page_youtube_wrap {
   position: relative;
   width: 100%;
   height: 0;
   padding-bottom: 56.25%;
}

.detail_common .detail_page_youtube {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
}

.info_wrap.type_01 {
   padding: 32px 0px;
   border-bottom: 1px solid #dedede;
}

.info_wrap.type_01 .category {
   line-height: 18px;
   margin-bottom: 8px;
   color: var(--sub_color03);
}

.info_wrap.type_01 .sub_info_wrap {
   display: flex;
   gap: 24px;
   width: fit-content;
   margin-left: auto;
   margin-right: auto;
}

.info_wrap.type_01 .date_wrap,
.info_wrap.type_01 .views_wrap {
   display: flex;
   gap: 8px;
}

.contents_wrap.type_01 {
   display: flex;
   flex-direction: column;
   gap: 40px;
   padding-bottom: 80px;
}

.contents_wrap.type_01 img {
   display: block;
   width: max-content;
   max-width: calc(100% - 32px);
   margin: 0px auto;
}

.contents_wrap.type_01.ql-editor {
   padding-top: 0px;
   padding-left: 0px;
   padding-right: 0px;
}

.contents_wrap.type_01.ql-editor .btn_download {
   cursor: pointer;
}

.btn_download .txt {
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
   height: 24px;
   line-height: 24px;
}

.control_wrap.type_01 {
   display: flex;
   justify-content: space-between;
   width: 100%;
   border-top: 1px solid var(--sub_color01);
   padding-top: 24px;
}

.control_wrap.type_01 * {
   color: var(--sub_color01);
}

.control_wrap.type_01 .btn,
.control_wrap.type_01 .prev_wrap,
.control_wrap.type_01 .next_wrap {
   display: flex;
   align-items: center;
}

.control_wrap.type_01 .btn {
   gap: 16px;
   width: calc((100% - 56px) / 2);
}

.control_wrap.type_01 .btn.prev {
   justify-content: flex-start;
}

.control_wrap.type_01 .btn.next {
   justify-content: flex-end;
}

.control_wrap.type_01 .prev_wrap,
.control_wrap.type_01 .next_wrap {
   gap: 8px;
}

.control_wrap.type_01 .btn_list {
   display: flex;
   justify-content: center;
   align-items: center;
   width: 56px;
   height: 56px;
   border: 2px solid var(--sub_color04);
   border-radius: 50%;
}

.control_wrap.type_01 .btn_list .icon {
   color: var(--sub_color04);
}

.control_wrap.type_01 {
   margin-bottom: 120px;
}

#root .list_wrap .a_btn .icon_wrap.type_01 {
   display: flex;
}

.list_wrap .a_btn .icon_wrap.type_01 {
   justify-content: center;
   align-items: center;
   width: 33px;
   min-width: 33px;
   height: 33px;
   border-radius: 50%;
   border: 1px solid var(--sub_color04);
   margin-left: auto;
}

.list_wrap .a_btn:hover .icon_wrap.type_01,
.list_wrap .a_btn:focus .icon_wrap.type_01 {
   border-color: var(--main_color03);
}

.list_wrap .a_btn .icon_wrap.type_01 .icon {
   color: var(--sub_color04);
}

.list_wrap .a_btn:hover .icon_wrap.type_01 .icon,
.list_wrap .a_btn:focus .icon_wrap.type_01 .icon {
   color: var(--main_color03);
}

.btn_download {
   display: flex;
   align-items: center;
   gap: 8px;
   width: 100%;
   height: 52px;
   border-radius: 4px;
   padding: 0px 24px;
   margin-top: 80px;
   background-color: var(--sub_color08);
}

.btn_download .icon {
   color: var(--sub_color04);
}

.btn_download .icon_download {
   margin-left: auto;
}

/* Detail Page End */

/* 팝업 Start */
.pop-parent {
   z-index: 9999;
   position: fixed;
   top: 0px;
   left: 0px;
   width: 100%;
   height: 100%;
   padding-bottom: 40px;
   background-color: rgba(0, 0, 0, 0.35);
}

.pop-parent .pop {
   display: block;
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   width: 500px;
}

.pop-parent .m-ratioBox-wrap {
   width: 100%;
   padding-top: 100%;
   position: relative;
   overflow: hidden;
}

.pop-parent .m-ratioBox-wrap .m-ratioBox {
   position: absolute;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   background-size: cover;
   background-position: center;
   background-color: var(--sub_color01);
}

.pop-parent .m-ratioBox-wrap img {
   display: block;
   width: 100%;
   height: 100%;
   object-fit: cover;
   position: absolute;
   left: 50%;
   top: 50%;
   transform: translateY(-50%) translateX(-50%);
   z-index: 1;
}

.pop-parent .m-ratioBox-wrap iframe {
   width: 105%;
   position: absolute;
   left: 50%;
   top: 50%;
   transform: translateY(-50%) translateX(-50%);
   z-index: 1;
}

.pop-parent .pop .btn-toggle {
   width: 40px;
   height: 40px;
   position: relative;
   background-color: black;
   cursor: pointer;
}

.pop-parent .pop .btn-toggle img {
   width: auto;
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%) rotate(-90deg);
   transition: all .3s;
}

.pop-parent .pop-btns {
   z-index: 9;
   position: absolute;
   bottom: 0px;
   left: 0px;
   display: flex;
   justify-content: space-between;
   width: 100%;
   height: 40px;
   background-color: #3c3c3c;
}

.pop-parent .pop-btns a {
   color: #fff;
   padding: 7px 14px;
}

.pop-parent .pop-btns #oneday_check {
   position: absolute;
   clip: rect(0, 0, 0, 0);
}

.pop-parent .pop-btns #oneday_check+label {
   display: flex;
   align-items: center;
   padding: 0 10px;
   font-size: 14px;
   color: #fff;
   cursor: pointer;
}

.pop-parent .pop-btns #oneday_check+label:before {
   content: "";
   display: inline-block;
   width: 12px;
   height: 12px;
   margin-right: 10px;
   border: 1px solid #fff;
}

.pop-parent .pop .swiper-btn-control {
   width: 8px;
   height: 11px;
   margin-left: 16px;
   position: relative;
   top: 2px;
}

.pop-parent .swiper-control {
   display: flex;
   align-items: center;
   position: absolute;
   top: 21px;
   right: 20px;
   z-index: 2;
}

#root .pop-parent .swiper-pagination {
   top: 21px;
   left: auto;
   right: 20px;
   width: fit-content;
}

.pop-parent .swiper-pagination-bullet {
   flex: 0 0 auto;
   width: 10px;
   height: 10px;
   margin: 0 5px;
   border-radius: 0;
   border: 1px solid white;
   opacity: 0.5;
   background-color: transparent;
}

.pop-parent .swiper-pagination-bullet-active {
   background-color: #fff;
   opacity: 1;
}

.pop-parent.active {
   right: -400px;
}

.pop-parent.active .btn-toggle {
   transform: rotate(180deg);
}

.pop-parent .pop .btn-close {
   width: 40px;
   height: 40px;
   background-color: black;
}

/* 팝업 End */

/* Footer Start */
#footer {
   margin-top: 120px;
   background-color: #484848;
}

.main_page+#footer,
.consumer_centric_educatio+#footer {
   margin-top: 0px;
}

#footer * {
   color: rgba(255, 255, 255, 0.4);
}

#footer .ft_nav_wrap {
   display: flex;
   gap: 24px;
}

#footer .ft_nav_wrap .ft_nav_item {
   width: 180px;
}

#footer .ft_nav_wrap .ft_nav_item .tit {
   color: #fff;
}

#footer .ft_nav_wrap .ft_nav_item .btn_link_wrap .btn_link_li:not(:last-of-type) .btn_link {
   margin-bottom: 12px;
}

#footer .ft_bottom_wrap {
   display: flex;
   gap: 40px;
   border-top: 1px solid rgba(255, 255, 255, 0.5)
}

#footer .ft_bottom_wrap .info_wrap {
   display: flex;
}

#footer .ft_bottom_wrap .info_wrap .txt {
   padding-right: 16px;
   border-right: 1px solid #fff;
   margin-right: 16px;
   color: #fff;
}

#footer .ft_bottom_wrap .logo {
   width: auto;
   height: 48px;
}

/* Footer End */

@media screen and (max-width:1800px) {

   /* Header Start */
   #header {
      width: 100%;
      padding: 0px 24px;
   }

   #header .gnb_ul .nav_dep2 {
      gap: 32px;
   }

   /* Header End */
   .main_page .swiper.type02 {
      width: 100%;
   }
}

@media screen and (max-width:1500px) {
   #header .gnb_ul .nav_dep2 {
      display: none;
   }

   .wrap {
      padding-left: 16px;
      padding-right: 16px;
   }

   /* Main Start */
   .main_page .section05 .swiper01 .mockup_img {
      max-width: 500px;
   }

   .main_page .section04 .item_wrap .item {
      width: 365px;
   }

   .main_page .section08 .container {
      padding-left: 16px;
      padding-right: 16px;
      max-width: 100%;
   }

   .main_page .section08 .container .text_wrap {
      max-width: 510px;
   }

   .main_page .section10.contact_us .item02 {
      padding-left: 24px;
      padding-right: 24px;
   }

   /* Main End */
   .elementary_education_curriculum .tab_wrap {
      flex-wrap: wrap;
      gap: 8px;
      border: 0px none;
   }

   .elementary_education_curriculum .tab_wrap .tab_item .btn_tab {
      border: 1px solid var(--sub_color05);
   }
}

@media screen and (max-width:1280px) {

   /* popup Start */
   .pop-parent .pop {
      width: 310px;
   }

   .pop-parent.active {
      right: -310px;
   }

   .list.type_dot::after {
      top: 7px;
      width: 4px;
      height: 4px;
   }

   .list.type_dot {
      padding-left: 10px;
   }

   /* Header Start */
   #header {
      top: 24px;
      padding: 0px 16px;
   }

   #header #gnb {
      padding-left: 16px;
      padding-right: 24px;
   }

   #header .gnb_ul {
      height: 58px;
   }

   #header .gnb_ul .logo {
      height: 26px;
   }

   #header .gnb_ul .gnb_dep2 {
      gap: 16px;
   }

   #header .gnb_ul .gnb_dep2 .gnb_dep2_item {
      height: 100%;
   }

   #header .gnb_ul .gnb_dep2 .global_wrap .icon_global {
      width: 22px;
   }

   #header .gnb_ul .gnb_dep2 .gnb_dep2_item>.btn_link,
   #header .gnb_ul .gnb_dep2 .gnb_dep2_item>.btn_global {
      height: 100%;
      padding: 0px;
   }

   #header .gnb_ul .gnb_dep2 .global_wrap,
   #header .gnb_ul .gnb_dep2 .open_gnb {
      width: fit-content;
   }

   .header_wrap .gnb_active_wrap {
      background-color: #fff;
   }

   .header_wrap .gnb_active_wrap.on {
      display: block;
   }

   .header_wrap .gnb_active_wrap * {
      color: var(--sub_color01);
   }

   .header_wrap .gnb_active_wrap .top_menu {
      display: flex;
      align-items: center;
      height: 67px;
      padding: 0px 16px;
   }

   .header_wrap .gnb_active_wrap .top_menu .logo {
      width: auto;
      height: 36px;
   }

   .header_wrap .gnb_active_wrap .gnb_active {
      width: 100%;
      padding: 10px 16px 0px 16px;
      border-top: 1px solid var(--sub_color05);
   }

   .header_wrap .gnb_active_wrap .dep01_item {
      display: block;
   }

   .header_wrap .gnb_active_wrap .dep01_item .tit {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 43px;
   }

   .header_wrap .gnb_active_wrap .dep01_item .tit.on * {
      color: var(--main_color03);
   }

   .header_wrap .gnb_active_wrap .dep01_item .tit .icon_arrow {
      transition: all 0.3s;
   }

   .header_wrap .gnb_active_wrap .dep01_item:not(:last-of-type) {
      margin-bottom: 0px;
   }

   .header_wrap .gnb_active_wrap .dep01_item .dep02_item_wrap {
      display: none;
   }

   .header_wrap .gnb_active_wrap .dep01_item .dep02_item_wrap.on {
      display: block;
   }

   .header_wrap .gnb_active_wrap .dep01_item .dep02_item_wrap .dep02_item {
      height: 35px;
      line-height: 35px;
      padding-left: 10px;
   }

   .header_wrap .gnb_active_wrap .dep01_item .dep02_item_wrap .dep02_item:hover,
   .header_wrap .gnb_active_wrap .dep01_item .dep02_item_wrap .dep02_item:focus {
      font-weight: 600;
      color: var(--main_color03);
      background-color: #e5efff;
   }

   .header_wrap .gnb_active_wrap .btn_close {
      top: calc(67px / 2);
      right: 8px;
      transform: translateY(-50%);
      width: 32px;
      height: 32px;
      background-color: transparent;
   }

   #header .gnb_ul .gnb_dep3 {
      top: 45px;
      padding: 10px 0px;
   }

   /* Header End */

   /* Main Start */
   .main_page .link_wrap.type01 .btn_link {
      gap: 8px;
      padding: 9px 12px;
   }

   .main_page .content.type01 {
      height: auto;
   }

   .main_page .content.type01 {
      background-size: cover;
   }

   .main_page .mb_wrap .mb .banner_text_wrap .main_tit .text,
   .main_page .mb_wrap .mb .banner_text_wrap .main_tit .point {
      -webkit-text-stroke-width: 1px;
   }

   .main_page .mb_wrap .mb.swiper-slide-active .banner_text_wrap .main_tit .point.text_G {
      animation: text_G 0.3s ease-in 0.3s forwards;
      margin-left: 35px;
   }

   .main_page .mb_wrap .mb.swiper-slide-active .banner_text_wrap .main_tit .point.text_O {
      animation: text_O 0.3s ease-in 0.3s forwards;
      margin-left: -100px;
   }

   .main_page .mb_wrap .mb.swiper-slide-active .banner_text_wrap .main_tit .point.text_S {
      animation: text_S 0.3s ease-in 0.3s forwards;
      margin-left: -117px;
   }

   @keyframes text_G {
      0% {
         margin-left: 35px;
      }

      100% {
         margin-left: 0px;
      }
   }

   @keyframes text_O {
      0% {
         margin-left: -100px;
      }

      100% {
         margin-left: 0px;
      }
   }

   @keyframes text_S {
      0% {
         margin-left: -117px;
      }

      100% {
         margin-left: 0px;
      }
   }

   .main_page .section01 {
      background-image: url(../images/home/mobile/section01_bg.webp);
   }

   .main_page .section02 {
      background-image: url(../images/home/mobile/section02_bg.webp);
   }

   .main_page .section03 {
      background-image: url(../images/home/mobile/section03_bg.webp);
   }

   .main_page .section01.content.type01,
   .main_page .section02.content.type01,
   .main_page .section03.content.type01 {
      height: 100vh;
   }

   .main_page .content.type01 .txt_wrap {
      width: 100%;
      padding-left: 20px;
      padding-right: 20px;
   }

   .main_page .content.type01 .txt_wrap .txt.point {
      width: 100%;
   }

   .main_page .content.type01 .txt_wrap .txt.point::after {
      padding: 16px 0px;
   }

   .main_page .section04 .item_wrap {
      flex-direction: column;
      align-items: center;
   }

   .main_page .section04 .item_wrap .item {
      width: 100%;
   }

   .main_page .section04 .item_wrap .item .btn_link {
      display: none;
   }

   .main_page .section05.content.type01 {
      background-image: url(../images/home/mobile/section05_bg.webp);
      background-size: 500px auto;
      background-color: var(--main_color03);
   }

   .main_page .section05 .swiper01 {
      padding: 0px 0px 200px 0px;
   }

   .main_page .section05 .swiper01 .slide_wrap .tit {
      display: none;
   }

   .main_page .section05 .swiper01 .mb_tit {
      display: block;
   }


   .main_page .section05 .swiper01 .swiper-slide {
      flex-direction: column;
      align-items: center;
      padding: 0px 16px;
   }

   .main_page .section05 .swiper01 .slide_wrap {
      order: 1;
      justify-content: center;
   }

   .main_page .section05 .swiper01 .slide_wrap * {
      width: 100%;
      text-align: center;
   }

   .main_page .section05 .swiper01 .slide_wrap .txt_img_wrap {
      width: 100%;
   }

   .main_page .section05 .swiper01 .slide_wrap .txt_img_wrap .img {
      display: none;
   }

   .main_page .section05 .swiper01 .slide_wrap .btn_link {
      margin-left: auto;
      margin-right: auto;
   }

   .main_page .section05 .swiper01 .mockup_img {
      order: 0;
      margin-bottom: 80px;
   }

   .main_page .section05 .swiper01 .swiper-pagination {
      bottom: 120px;
      justify-content: center;
   }

   .main_page .tit_wrap.type01 .btn_link {
      width: 32px;
      min-width: 32px;
      height: 32px;
   }

   .main_page .section08 .container {
      display: block;
   }

   .main_page .section08 .container .text_wrap {
      position: static;
      max-width: 100%;
   }

   .main_page .section08 .container .scroll_content {
      display: block;
   }

   .main_page .section08 .container .scroll_content .item.type02 {
      margin-left: 0px;
   }

   .main_page .section09 .swiper04 {
      padding-bottom: 56px;
   }

   .main_page .section09 .tit_wrap {
      display: block;
   }

   .main_page .section09 .tit_wrap .tit {
      text-align: center;
   }

   .main_page .section09 .tit_wrap .btn_link {
      margin-left: auto;
      margin-right: auto;
      margin-top: 16px;
   }

   .main_page .section09 .swiper04 .swiper-pagination-bullet {
      width: 48px;
      height: 2px;
   }

   .main_page .section10.contact_us {
      display: block;
      height: auto;
   }

   .main_page .section10.contact_us .item01,
   .main_page .section10.contact_us .item02 {
      width: 100%;
      max-width: 100%;
      padding-left: 16px;
      padding-right: 16px;
   }

   .main_page .section10.contact_us .item01 {
      padding-top: 80px;
      padding-bottom: 80px;
   }

   .main_page .section10.contact_us .item02 {
      padding-top: 40px;
      padding-bottom: 80px;
   }

   .main_page .section10.contact_us .item01 .icon_wrap .icon {
      width: 30px;
      height: 30px;
   }

   /* Main End */

   /* Sub Page Start */
   .sub_page .mb_wrap {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 320px;
      padding-top: 0px;
   }

   .sub_page .mb_wrap .wrap {
      margin-left: 0px;
      margin-top: 36px;
   }

   .sub_page .mb_wrap .top_item .nav {
      display: none;
   }

   .sub_page .mb_wrap .tit {
      line-height: 36px;
      font-size: 26px;
   }

   .sub_page .mb_wrap>.wrap>.gnb {
      overflow: hidden;
      max-height: 40px;
      background-color: transparent;
      transform: translate(-50%, calc(100% - 40px));
   }

   .sub_page .mb_wrap>.wrap>.gnb.on {
      overflow: visible;
      max-height: 9999px;
   }

   .sub_page .mb_wrap>.wrap>.gnb .gnb_item_wrap {
      flex-direction: column;
      gap: 0px;
      border-radius: 20px 20px 0px 0px;
      background-color: #fff;
   }

   .sub_page .mb_wrap>.wrap>.gnb .gnb_item_wrap.pc_only {
      display: none;
   }

   .sub_page .mb_wrap>.wrap>.gnb .gnb_item_wrap.mo_only {
      display: flex;
      border-radius: 20px;
      box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.16);
   }

   .sub_page .mb_wrap>.wrap>.gnb .gnb_item_wrap .item {
      width: 100%;
      padding: 0px 24px;
   }

   .sub_page .mb_wrap>.wrap>.gnb .gnb_item_wrap .item .icon_arrow {
      display: none;
   }

   .sub_page .mb_wrap>.wrap>.gnb .gnb_item_wrap .item.top {
      display: flex;
      order: -1;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid var(--sub_color05);
   }

   .sub_page .mb_wrap>.wrap>.gnb .gnb_item_wrap .item.top .icon_arrow {
      display: block;
   }

   .sub_page .mb_wrap>.wrap>.gnb .gnb_item_wrap .gnb_item {
      width: 100%;
      height: 40px;
      line-height: 40px;
      text-align: left;
      font-size: 11px;
   }

   .sub_page .mb_wrap>.wrap>.gnb .gnb_item_wrap .gnb_item.on {
      font-size: 13px;
      font-weight: 600;
      color: var(--sub_color01);
   }

   .sub_page .mb_wrap>.wrap>.gnb .gnb_item_wrap .gnb_item.on::after {
      display: none;
   }

   /* Sub Page End */

   /* Company Start */
   .company .wrap {
      padding-left: 16px;
      padding-right: 16px;
   }

   .sub_page.core_values .section01 .item01 {
      display: block;
      padding-left: 16px;
      padding-right: 16px;
   }

   .sub_page.core_values .section01 .item01 .mv {
      border-radius: 16px;
      margin-left: auto;
      margin-right: auto;
   }

   .sub_page.core_values .section02 .item.type01 {
      display: block;
   }

   .sub_page.core_values .section02 .left_content {
      gap: 8px;
      margin-bottom: 24px;
   }

   .sub_page.core_values .section02 .item.type01 .right_content .banner_item {
      height: auto;
      border-radius: 16px;
      padding: 24px 16px;
      background-image: none;
      background-color: #e5efff;
   }

   .sub_page.core_values .section02 .item.type01 .right_content .banner_item {
      margin-bottom: 24px;
   }

   .sub_page.core_values .section02 .item02.type01 .right_content .banner_item {
      color: #fff;
      background-color: var(--main_color03);
   }

   .sub_page.core_values .section01 .item01 .icon {
      width: 16px;
   }

   .sub_page.core_values .section01 .item01 .text_wrap {
      padding: 16px;
   }

   .vision_mission .section02 {
      padding-left: 16px;
      padding-right: 16px;
   }

   .vision_mission .section02 .list_wrap {
      gap: 24px;
      flex-wrap: wrap;
   }

   .vision_mission .section02 .list_wrap .img {
      width: 159px;
   }

   .vision_mission .section03 .tab_control_wrap {
      display: none;
   }

   .vision_mission .section03 .tab_item_wrap {
      border: 0px none;
      padding: 0px;
   }

   .vision_mission .section03 .tab_item_wrap .tab_item {
      overflow: hidden;
      flex-direction: column;
      display: flex;
      gap: 0px;
      align-items: center;
      box-shadow: inset 0px 0px 0px 1px var(--sub_color05);
      border-radius: 24px 24px 24px 24px;
      padding-bottom: 32px;
   }

   .vision_mission .section03 .tab_item_wrap .tab_item:not(:last-of-type) {
      margin-bottom: 32px;
   }

   .vision_mission .section03 .tab_item_wrap .tab_item .tab_tit {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 50px;
      border: 1px solid var(--main_color03);
      color: #fff;
      background-color: var(--main_color03);
   }

   .vision_mission .section03 .tab_item_wrap .tab_item .text_wrap {
      padding: 24px 20px 32px;
   }

   .vision_mission .section03 .tab_item_wrap .tab_item .img {
      width: 158px;
   }

   .contact_inquire .top_head_wrap {
      border-bottom: 1px solid var(--sub_color05);
      padding-bottom: 14px;
      margin-bottom: 24px;
   }

   .contact_inquire .inquire_from .item_01 {
      display: block;
   }

   .contact_inquire .inquire_from .input_wrap input,
   .contact_inquire .inquire_from .input_wrap textarea {
      width: 100%;
      font-size: 13px;
   }

   .contact_inquire .inquire_from .input_wrap input {
      height: 48px;
   }

   .contact_inquire .inquire_from .input_wrap.message_wrap textarea {
      height: 175px;
   }

   .contact_inquire .inquire_from .input_wrap .label_upload {
      height: 40px;
   }

   .contact_inquire .privacy_wrap .info_wrap {
      padding: 24px 16px;
      margin-bottom: 24px;
   }

   .contact_inquire .inquire_from .input_wrap.upload_wrap {
      padding-bottom: 24px;
      margin-bottom: 24px;
   }

   .contact_inquire .btn_submit {
      width: 100%;
      height: 58px;
   }

   /* Company End */

   /* Math Start */
   .math_education_standards .section01 {
      display: block;
   }

   .math_education_standards .section02 {
      height: auto;
      padding-top: 48px;
      padding-bottom: 48px;
      background-position: center center;
   }

   /* Math End */

   /* Education Start */
   .consumer_centric_educatio .section02 .item_warp {
      display: block;
   }

   .consumer_centric_educatio .section02 .item_warp .item {
      width: 100%;
      height: auto;
      min-height: 180px;
      padding: 32px 24px;
      background-size: 53px auto;
   }

   .consumer_centric_educatio .section02 .item_warp .item:not(:last-of-type) {
      margin-bottom: 16px;
   }

   .consumer_centric_educatio .section03 {
      flex-direction: column;
      align-items: center;
   }

   .consumer_centric_educatio .section03 .text_wrap .tit {
      width: 100%;
      text-align: center;
      border: 0px none;
   }

   .consumer_centric_educatio .section03 .mockup_img {
      width: 100%;
      max-width: 275px;
   }

   .consumer_centric_educatio .section03 .mockup_img+.txt {
      margin-top: 40px;
   }

   .consumer_centric_educatio .contact_banner {
      height: auto;
      background-position: center center;
   }

   .consumer_centric_educatio .contact_banner {
      padding: 40px 46px;
   }

   .consumer_centric_educatio .contact_banner .btn_link {
      width: 124px;
      height: 48px;
   }

   /* Education End */

   /* Curriculum General Start */
   .elementary_education_curriculum .tab_wrap {
      flex-wrap: wrap;
      gap: 7px;
      width: 100%;
      border: 0px none;
   }

   .elementary_education_curriculum .tab_wrap .tab_item {
      width: calc(33.3% - (14px/3));
   }

   .elementary_education_curriculum .tab_wrap .tab_item .btn_tab {
      width: 100%;
      height: 42px;
      border: 1px solid var(--sub_color05);
   }

   .elementary_education_curriculum .product_list_wrap .product_wrap {
      gap: 48px 16px;
   }

   .elementary_education_curriculum .product_list_wrap .product_wrap .product_item {
      width: calc(50% - 8px);
   }

   /* Curriculum General End */

   /* Curriculum Detail Start */
   .general_math_detailpage {
      flex-direction: column;
      gap: 0px;
   }

   .general_math_detailpage .product_img_wrap,
   .general_math_detailpage .info_wrap {
      width: 100%;
   }

   .general_math_detailpage .info_wrap .info_item .tit {
      width: 64px;
   }

   .general_math_detailpage .store_wrap .store_item_wrap .store_item .btn_link {
      padding: 8px 12px;
   }

   .product_preview_wrap .product_preview_swiper {
      width: calc(100% - 32px);
      max-width: 850px;
      height: auto;
   }

   .general_math_detailpage .product_img_wrap .thumbnail {
      width: 100%;
      height: auto;
   }

   /* Curriculum Detail End */

   /* Arts Start */
   .program_overview .section02 .content {
      display: block;
   }

   .program_overview .section02 .content .main_img {
      width: 100%;
   }

   .program_overview .section02 .content .text_wrap .num {
      display: none;
   }

   .program_overview .section02 .content .text_wrap {
      width: 100%;
      padding: 32px 16px;
   }

   .program_overview .section02 .content.type02 .text_wrap {
      padding: 32px 16px;
   }

   .program_overview .section02 .content .text_wrap .tit::before {
      width: 24px;
      height: 3px;
   }

   .education_curriculum .section02 * {
      text-align: center;
   }

   .education_curriculum .section02 .item_wrap .item {
      flex-direction: column;
      align-items: center;
      gap: 24px;
   }

   .education_curriculum .section02 .item_wrap .item .main_img {
      width: 100%;
   }

   .education_curriculum .section02 .item_wrap .item .num {
      padding: 8px 16px;
   }

   .education_curriculum .section02 .item_wrap .item.type_arrow {
      width: 100%;
      height: auto;
      padding: 24px 0px;
   }

   .preliminary_diagnostic_program .section01 .list_wrap {
      padding: 24px 16px;
   }

   .preliminary_diagnostic_program .section01 .list_wrap .list .icon {
      padding-top: 3px;
   }

   .preliminary_diagnostic_program .section01 .item_wrap {
      flex-wrap: wrap;
      gap: 48px 0px;
   }

   .preliminary_diagnostic_program .section01 .item_wrap .item {
      width: 38%;
   }

   .preliminary_diagnostic_program .section01 .item_wrap .item.type_arrow {
      width: 24%;
   }

   .preliminary_diagnostic_program .section01 .item_wrap .item.type_arrow.pc_only {
      display: none;
   }

   .preliminary_diagnostic_program .section01 .item_wrap .item .cir01 {
      width: 100px;
      height: 100px;
      padding: 10px;
      margin-left: auto;
      margin-right: auto;
   }

   .preliminary_diagnostic_program .section01 .item_wrap .item .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
   }

   .preliminary_diagnostic_program .section01 .item_wrap .item .list_wrap02 .list:not(:last-of-type) {
      margin-bottom: 8px;
   }
   .preliminary_diagnostic_program .section01 .item_wrap .item .list_wrap02 .list.type_dot::after{
      top: 8px;
   }

   .membership .section01 .list_wrap01 {
      flex-direction: column;
      gap: 24px;
   }

   .membership .section01 .list_wrap01>.list {
      width: 100%;
      max-width: 100%;
      padding: 32px 24px;
   }

   .membership .section01 .list_wrap01>.list .tit::after {
      bottom: 2px;
   }

   /* Arts End */

   /* Blueberry Espresso Start */
   .top_nav.type_01 .search_box {
      width: 100%;
      height: 48px;
      border-width: 1px;
   }

   .math_letters .section02 .list_wrap {
      gap: 32px;
   }

   .math_letters .section02 .list_wrap .list {
      width: calc((100% - 32px * 2) / 3);
   }

   .blueberry_espresso.news .list_wrap .item .a_btn {
      gap: 32px;
   }

   .blueberry_espresso.news .list_wrap .item .a_btn .text_wrap {
      width: 50%;
   }

   .blueberry_espresso.news .list_wrap .item .thumbnail {
      width: 280px;
   }

   /* Blueberry Espresso End */

   /* Detail Page Common Start */
   .detail_common {
      margin-top: 105px;
   }

   .info_wrap.type_01 {
      padding: 16px 0px;
   }

   /* Detail Page Common End */

   /* Footer Start */
   #footer {
      padding: 32px 24px 40px 24px;
      margin-top: 80px;
   }

   #footer .wrap {
      padding: 0px;
   }

   #footer .ft_bottom_wrap {
      display: block;
      border: 0px none;
   }

   #footer .ft_bottom_wrap .info_wrap {
      display: block;
   }

   #footer .ft_bottom_wrap .info_wrap .txt {
      border: 0px none;
   }

   #footer .ft_nav_wrap {
      display: none;
   }

   /* Footer End */
}

@media screen and (max-width:768px) {

   /* Header Start */
   #header {
      padding: 0px;
      top: 0px;
   }

   #header #gnb {
      border-radius: 0px;
   }

   /* Header End */

   /* Main Start */
   .main_page .section10.contact_us .item02 .input_box_wrap {
      display: block;
   }

   /* Main End */

   /* Blueberry Espresso Start */
   .wrap.detail_common {
      width: calc(100% - 32px);
      border-width: 1px;
      padding-left: 0px;
      padding-right: 0px;
   }

   .math_letters .section02 .list_wrap .list {
      width: 100%;
      max-width: 100%;
   }

   .blueberry_espresso.type_notice .top_nav .top_item_wrap {
      flex-direction: column;
   }

   .blueberry_espresso.type_notice .top_nav .top_item_wrap .search_from {
      width: 100%;
   }

   .blueberry_espresso.type_notice .top_nav.type_01 {
      padding-bottom: 12px;
   }

   .blueberry_espresso.type_notice .top_nav .top_item_wrap .text_count {
      order: 1;
      width: 100%;
      text-align: left;
   }

   .blueberry_espresso.type_notice .list_wrap .a_btn {
      padding: 16px 8px;
   }

   .blueberry_espresso.type_notice .list_wrap .a_btn .notice {
      padding: 6px 12px;
   }

   .control_wrap.type_01 .btn_list {
      width: 32px;
      height: 32px;
      border-width: 1px;
   }

   .control_wrap.type_01 .btn .tit {
      display: none;
   }

   .blueberry_espresso.news .list_wrap .item .a_btn {
      padding: 16px 0px;
   }

   .blueberry_espresso.news .list_wrap .item .thumbnail {
      display: none;
   }

   .blueberry_espresso.news .list_wrap .item .a_btn .text_wrap {
      width: calc(100% - (24px + 32px));
      max-width: none;
   }

   .blueberry_espresso.news .list_wrap .item .txt * {
      line-height: 20px;
      max-height: calc(20px * 3);
   }

   .list_wrap .a_btn .icon_wrap.type_01 {
      width: 24px;
      min-width: 24px;
      height: 24px;
   }

   .blueberry_espresso.news .list_wrap .a_btn .icon_wrap.type_01 {
      margin-left: 0px;
   }

   .list_wrap .a_btn .icon_wrap.type_01 .icon {
      font-size: 10px;
   }

   /* Blueberry Espresso End */


   /* popup Start */
   .pop-parent {
      top: 50%;
      right: unset;
      left: 50%;
      transform: translate(-50%, -50%);
   }

   .pop-parent .pop-btns {
      transform: translateY(100%);
      top: unset;
      bottom: 0;
      left: 0;
      width: 100%;
      justify-content: space-between;
   }

   .pop-parent .pop .btn-toggle {
      display: none;
   }

   .pop-parent .pop .btn-close {
      display: block;
   }

   /* popup End */

   /* privacy Start */
   .privacy_agreement_all_wrap .privacy_agreement_all {
      width: calc(100% - 32px);
   }

   .privacy_agreement_all_wrap .text_wrap .fs_type_08 {
      font-size: 14px;
      margin-bottom: 24px;
   }

   /* privacy End */
}

@media screen and (max-width:350px) {


   /* main Start */
   .main_page .mb_wrap .mb {
      overflow: hidden;
   }

   .main_page .mb_wrap .mb.swiper-slide-active .banner_text_wrap .main_tit {
      font-size: 28px;
   }

   .main_page .mb_wrap .mb.swiper-slide-active .banner_text_wrap .main_tit .point.text_G {
      animation: text_G 0.3s ease-in 0.3s forwards;
      margin-left: 35px;
   }

   .main_page .mb_wrap .mb.swiper-slide-active .banner_text_wrap .main_tit .point.text_O {
      animation: text_O 0.3s ease-in 0.3s forwards;
      margin-left: -87px;
   }

   .main_page .mb_wrap .mb.swiper-slide-active .banner_text_wrap .main_tit .point.text_S {
      animation: text_S 0.3s ease-in 0.3s forwards;
      margin-left: -102px;
   }

   @keyframes text_G {
      0% {
         margin-left: 35px;
      }

      100% {
         margin-left: 0px;
      }
   }

   @keyframes text_O {
      0% {
         margin-left: -87px;
      }

      100% {
         margin-left: 0px;
      }
   }

   @keyframes text_S {
      0% {
         margin-left: -102px;
      }

      100% {
         margin-left: 0px;
      }
   }

   /* main End */

   /* Blueberry Espresso Start */
   .elementary_education_curriculum .product_list_wrap .product_wrap .category_wrap .category {
      max-width: 50%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 9px;
   }

   /* Blueberry Espresso Start */

   .privacy_agreement_all_wrap .text_wrap .item {
      flex-direction: column;
      gap: 8px;
      margin-bottom: 24px;
   }

   .privacy_agreement_all_wrap .text_wrap .item:first-of-type {
      margin-top: 24px;
   }

   .privacy_agreement_all_wrap .text_wrap .item .tit {
      color: var(--subColor_01);
   }
}